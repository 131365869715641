@import "./tailwind";
@import "page-home";
@import "page-questions";
@import "page-result";

body {
}

a,
button {
    cursor: pointer;
}

.primary-block {
    @apply bg-primary text-white inline-flex items-center justify-center font-bold;
    border: 1px solid white;
    border-radius: 4px;
}
.container {
    @apply px-5 mx-auto;
}
.gold-box {
    // @apply bg-gradient-to-r from-gold-light to-gold text-gold-text font-bold py-1 items-center flex justify-center;
    @apply bg-gold text-gold-text font-bold py-1 items-center flex justify-center;
}
.result-title {
    @apply bg-gradient-to-r from-gold-light to-gold text-gold-text font-bold py-1 items-center inline-flex justify-center text-xl;
    padding-left: 1em;
    padding-right: 1em;
}
.highlight {
    @apply text-hightlight;
}
.shadowing {
    text-shadow: 0.1em 0.1em 0.1em rgba(0, 0, 0, 0.3);
}

.App {
    @apply bg-gray-900;
    .app-wrapper {
        @apply max-w-[480px] mx-auto overflow-x-hidden relative;
        @apply bg-gray-100;
        .page {
            @apply min-h-dvh w-full relative;
        }
    }
}

.page__bg {
    @apply top-0 left-0 w-full h-full object-cover;
}

.loading-screen {
    @apply fixed top-0 left-0 w-full h-full flex items-center justify-center;
    @apply bg-primary-dark text-white;
    opacity: 0.8;
    z-index: 99;
    // .loading-bar-bg{
    //     @apply relative top-0 left-0 w-full h-auto bg-white;
    //     .loading-bar{
    //         @apply relative top-0 left-0 bg-primary;
    //         height: 2px;
    //         transition: width 0.2s ease;
    //         will-change: width;
    //     }
    // }
}

.popup {
    @apply fixed top-0 left-0 w-full h-full flex justify-center px-md pt-md pb-lg flex-col;
    z-index: 99;
    .popup-overlay {
        @apply absolute top-0 left-0 w-full h-full;
        background: rgba(black, 0.8);
        z-index: 1;
    }
    .popup-content {
        @apply bg-white rounded-lg text-black mb-sm max-w-[640px] mx-auto;
        overflow-y: auto;
        z-index: 2;
    }
    .popup-close {
        @apply text-white;
        z-index: 3;
        font-size: 14px;
    }
}

.plaintext {
    ol {
        counter-reset: item;
    }
    li {
        display: block;
        position: relative;
    }
    ol li:before {
        content: counters(item, ".") " ";
        counter-increment: item;
        display: inline;
        position: absolute;
        left: 0;
        top: 0;
    }
    ol ul li:before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 0.5em;
        top: 0.6em;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: black;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-bold;
        line-height: 1.1;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
    h1 {
        @apply text-center text-lg;
    }
    h2 {
        @apply text-md;
    }
    h3 {
        @apply text-base;
    }
    h4 {
        @apply text-sm;
    }
    h5 {
        @apply text-xs;
    }
    h6 {
        @apply text-xs;
    }

    ul {
        list-style: disc;
    }
    ol {
        list-style: decimal;
    }
    ul,
    ol {
        // padding-left: 1.5rem;
        margin-bottom: 2rem;
        ul,
        ol {
            margin: 1rem 0;
        }
        li {
            margin-bottom: 0.5rem;
            padding-left: 1.5rem;
            p {
                margin-bottom: 0.5rem;
            }
        }
    }
    .table-container {
        max-width: calc(100vw - 100px);
        overflow-x: auto;
    }
    table {
        vertical-align: top;
        border: 1px solid #ccc;
        th,
        td {
            vertical-align: top;
            padding: 0.25em;
            border: 1px solid #ccc;
            ul,
            ol {
                margin: 0;
            }
        }
    }
}
