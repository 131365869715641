.page-announcement{
    @extend .page-home;
    background-size: contain !important;
    .bg{
        @apply absolute top-0 left-0 w-full h-full;
        background-repeat: repeat;
        background-position: center top;
        background-size: contain;
        z-index: 0;
    }
    > *{
        @apply relative;
        z-index: 2;
    }
    .result-table{
        @apply text-left mx-auto px-sm;
        td{
            @apply py-xs pr-md;
        }
    }
}

.page-home{
    // background-image: url('../../public/images/bg-home.jpg');
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    @apply text-center text-white;

    .kv-container{
        @apply pb-[100%] relative;
    }

    .kv{
        @apply absolute w-full h-full top-0 left-0;
        transform-origin: 50% 50%;
        animation: zoomout 2s;
        > *{
            @apply block absolute inset-0 w-full h-full top-0 left-0;
        }
        img{
            @apply block absolute inset-0 w-full h-full top-0 left-0 object-cover;
        }


        .prize-1,
        .prize-2,
        .coins-1,
        .coins-2{
            animation: floating 4s infinite ease-in-out;
        }
        .prize-1{   
            animation-duration: 4s;
        }
        .prize-2{
            animation-duration: 7s;
        }
        .coins-1{
            animation-duration: 5s;
        }
        .coins-2{
            animation-duration: 6s;
        }
    }
}

@keyframes zoomout {
    0% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes floating{
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(-2%);
    }
    100%{
        transform: translateY(0);
    }
}